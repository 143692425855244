import React from "react";
//import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
//import { ai, getAppInsights } from "./AppInsights";
//import {SeverityLevel} from '@microsoft/applicationinsights-web';
//import Loading from "components/shared-components/Loading";

export const AppAnalytics = ({ children }) => {
  // const [initialized, setInitialized] = useState(false);
  
  // useEffect(()=>{
  //   if (!initialized) {
  //     ai.initialize();
  //     setInitialized(true);
  //   }
  // },[initialized]);

  // if (!initialized) {
  //   return (<Loading/>);
  // }
  return (
    <React.Fragment>
     {/* <AppInsightsContext.Provider value={ai.reactPlugin}> */}
      {children}
     {/* </AppInsightsContext.Provider> */}
    </React.Fragment>
  );
};

export const trackAppEvent = (eventName, data) => {
    // const appInsights = getAppInsights();
    // appInsights.trackEvent({ name: eventName, data });
}

export const trackAppException = (error, message) => {
    // const appInsights = getAppInsights();
    // appInsights.trackException({ error, severityLevel: SeverityLevel.Error }, {message});
}
